import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { AppContext } from '../../reducer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { QueryOrderByNo } from '../../api/index';
import { getCurrentClientType } from '../../utils/index';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Toast  } from 'react-vant';

enum EPayStatus {
  LOADING = 0,
  SUCCESS = 1,
  FAIL = 2,
}
export default function Confirm() {
  const { state, send }: any = React.useContext(AppContext);
  const [ drawer, setDrawer ] = React.useState(EPayStatus.LOADING);
  const [ expanded, setExpanded ] = React.useState(false);
  const [ payOpen, setPayOpen ] = React.useState(false);
  const [ keyboardDisplay, setKeyboardDisplay ] = React.useState(false);
  let toast: any;

  React.useEffect(() => {
    if (drawer === EPayStatus.LOADING) {
      toast = Toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
    }
    const env = getCurrentClientType();
    const out_trade_no = sessionStorage.getItem('out_trade_no') || '';
    const pay_type = sessionStorage.getItem('pay_type') || '';
      QueryOrderByNo(out_trade_no, pay_type as any)
        ?.then(({ trade_state }: any) => {
          toast?.clear();
          if (trade_state === 'SUCCESS') {
            setDrawer(EPayStatus.SUCCESS);
          } else {
            setDrawer(EPayStatus.FAIL);
          }
          // {'amount': {'currency': 'CNY', 'payer_currency': 'CNY', 'payer_total': 14, 'total': 14},
          // 'appid': 'wx272f5a364874daea', 'attach': '', 'bank_type': 'OTHERS', 'mchid': '160042471
          // 7', 'out_trade_no': '4908154241232666625', 'payer': {'openid': 'oZUCFwyxJNOzZtkp_OWxV6pz
          // ao5g'}, 'promotion_detail': [], 'success_time': '2024-07-09T06:12:49+08:00', 'trade_stat
          // e': 'SUCCESS', 'trade_state_desc': '支付成功', 'trade_type': 'JSAPI', 'transaction_id':
          // '4200002396202407091048914549'}
          sessionStorage.removeItem('out_trade_no');
          sessionStorage.removeItem('pay_type');
        });
  }, []);
  return (
    <>
      <SwipeableDrawer
        className='payment-result-drawer'
        elevation={0}
        anchor={'right'}
        open={true}
        onClose={() => {}}
        onOpen={() => {}}
        sx={{
          border: '10px solid red', 
          '& .MuiDrawer-paper': {
            left: 0 // 解决左侧不对齐的问题
          }
        }}
      >
				<Container style={{ textAlign: 'center', width: '100%', marginTop: '100px' }}>
        {
          drawer === EPayStatus.SUCCESS &&
          <Box component="form" onSubmit={() => {}} noValidate sx={{ margin: '0 24px' }}>
            <CheckCircleIcon style={{ color: '#4caf50', fontSize: '72px' }}></CheckCircleIcon>
            <Typography variant='h4' component='h1' gutterBottom>付款成功</Typography>
            <Typography variant='body1'gutterBottom>您的付款已成功处理,感谢您的购买!</Typography>
            <Stack spacing={2} direction='column' alignItems='center' sx={{ marginTop: 3}}>
              <Button variant='contained' color='primary' sx={{ color: '#fff' }} onClick={() => send({ type: 'home' }) }>返回首页</Button>
              <Button variant='outlined' color='primary' onClick={() => send({ type: 'order', value: '待收货' }) }>查看订单</Button>
            </Stack>
          </Box>
        }
        {
          drawer === EPayStatus.FAIL &&
          <Box component="form" onSubmit={() => {}} noValidate sx={{ margin: '0 24px' }}>
              <PriorityHighIcon style={{ color: 'orange', fontSize: '45px' }}></PriorityHighIcon>
              <Typography variant='h4' component='h1' gutterBottom>支付异常</Typography>
              <Typography variant='body1'gutterBottom>付款处理失败，请联系客服!</Typography>
              <Stack spacing={2} direction='column' alignItems='center' sx={{ marginTop: 3}}>
                <Button variant='contained' color='primary' sx={{ color: '#fff' }} onClick={() => send({ type: 'home' }) }>返回首页</Button>
                <Button variant='outlined' color='primary' onClick={() => send({ type: 'order' }) }>联系客服</Button>
              </Stack>
          </Box>
        }
				</Container>
    	</SwipeableDrawer>
    </>
  );
}
