

const prefix = '/api/v1/life';
const origin = window.location.origin;
const default_header = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    "x-token": window.location.hostname !== 'localhost' ? "154103258854" : '56822105536214'
}

export function create_ali_wap_pay(description: string, total_fee: number, allow_call_when_received: boolean, recipient: any, deliveryTimeRange: string[], goods_list: string[]) {
    const params = {
        description,
        total_fee,
        allow_call_when_received,
        deliveryTimeRange,
        recipient,
        shipping: 0,
        score_discount: 0,
        payment: total_fee,
        goods_list,
        buy_channel: 'online',
        return_url: 'https://life.hnwg.net'
    }
    return fetch(
       `${origin}${prefix}/payment/ali/wap`,
        {
            method: 'POST',
            headers: default_header,
            body: JSON.stringify({
                description: params.description,
                total_fee: params.total_fee,
                return_url: params.return_url,

                allow_call_when_received: params.allow_call_when_received,
                deliveryTimeRange: params.deliveryTimeRange,
                recipient: params.recipient,
                shipping: params.shipping,
                score_discount: params.score_discount,
                payment: params.payment,
                goods_list: params.goods_list,
                buy_channel: params.buy_channel
            })
        }
    )
    .then(res => res.json())
}

export function create_cod_order(description: string, total_fee: number, allow_call_when_received: boolean, recipient: any, deliveryTimeRange: string[], goods_list: string[]) {
    return fetch(
        `${origin}${prefix}/payment/ali/wap`,
         {
             method: 'POST',
             headers: default_header,
             body: JSON.stringify({
                 description: description,
                 total_fee: total_fee,
 
                 allow_call_when_received: allow_call_when_received,
                 deliveryTimeRange: deliveryTimeRange,
                 recipient: recipient,
                 shipping: 0,
                 score_discount: 0,
                 payment: 0,
                 goods_list: goods_list,
                 buy_channel: 'online'
             })
         }
     )
     .then(res => res.json())
}

export function create_wx_jsapi_order(description: string, total_fee: number,allow_call_when_received: boolean, recipient: any, deliveryTimeRange: string[], goods_list: string[])   {
    const params = {
        description,
        total_fee,
        deliveryTimeRange,
        allow_call_when_received,
        recipient,
        shipping: 0,
        score_discount: 0,
        payment: total_fee,
        goods_list,
        buy_channel: 'online'
    }
    return fetch(`${origin}${prefix}/payment/wx/jsapi`,{
        method: 'POST',
        headers: default_header,
        body: JSON.stringify(params)
    })
    .then(res => res.json() )
}

export function getWxConfig(configUrl: string) {
    return fetch(`${origin}${prefix}/auth/wx/getwxconfig`, {
        method:"POST",
        headers: default_header,
        body: JSON.stringify({
            config_auth_url: configUrl
        })
      })
      .then(result => result.json())
}

export function getWxOpenid(code: string, state: string) {
    return fetch(`${origin}${prefix}/auth/wx/openid`, {
        method: "post",
        headers: default_header,
        body: JSON.stringify({ code, state })
    })
    .then( res => res.json())
}

export function getAliOpenid(auth_code: string) {
    try {
        return fetch(
            `${origin}${prefix}/auth/ali/buyerid`,
            {
              method: "POST",
              headers: default_header,
              body: JSON.stringify({ auth_code })
            }
          )
          .then(res => res.json())
          .catch(res => {
            // console.log(res);
          })
    } catch (error) {
        console.log(error);
    }
}

export function QueryOrderByNo(out_trade_no: string,  pay_type: 'weixin' | 'alipay' | 'other') {
    if (pay_type === 'weixin') {
        return fetch(`${origin}${prefix}/order/wx/query?out_trade_no=${out_trade_no}`, { headers: default_header }).then(res => res.json()).then(result => ({ trade_state: result.data?.trade_state}));
    }
    if (pay_type === 'alipay') {
        return fetch(`${origin}${prefix}/order/ali/query?out_trade_no=${out_trade_no}`, { headers: default_header }).then(res => res.json()).then(result => ({ trade_state: result.data?.status === 'TRADE_SUCCESS' && 'SUCCESS' }));
    }
    
}

export function getAccount() {
    return fetch(`${origin}${prefix}/user/get-user`, { headers: default_header}).then(res => res.json());
}

export function getMyOrders(status: string) {
    return fetch(`${origin}${prefix}/user/order?status=${status}`, { headers: default_header }).then(res => res.json());
}

export function getGoodsList() {
    return fetch(`${origin}${prefix}/goods/list`, { headers: default_header }).then(res => res.json());
}

export function getGoodsByIds(ids: string[], out_trade_no: string) {
    return fetch(`${origin}${prefix}/goods/query/byids`, { method: 'POST', headers: default_header, body: JSON.stringify({ ids, out_trade_no }) }).then(res => res.json())
}

export function cancelOrder(out_trade_no: string) {
    return fetch(`${origin}${prefix}/order/cancel/${out_trade_no}`, { method: 'POST', headers: default_header  }).then(res => res.json());
}

export function continuePay(out_trade_no: string) {
    return fetch(`${origin}${prefix}/payment/continue/${out_trade_no}`, { method: 'POST', headers:default_header }).then(res => res.json());
}

export function SaveCart(item: {}, type: | 'increase' | 'decrease' | 'clear') {
    return fetch(`${origin}${prefix}/user/cart/cache?action=${type}`, { method: 'POST', headers: default_header, body: JSON.stringify(item)}).then(res => res.json());
}

export function FetchCart() {
    return fetch(`${origin}${prefix}/user/cart/fetch`,{ headers: default_header }).then(res => res.json());
}

export function SaveAddress(addresses: any[]) {
    return fetch(`${origin}${prefix}/user/address/save`, { method: 'POST', headers: default_header, body: JSON.stringify({ addresses }) }).then(res => res.json());
}
export function FetchAddress() {
    return fetch(`${origin}${prefix}/user/address`, { headers: default_header }).then(res => res.json());
}
export function GoodsSearch(keywords: string) {
    return fetch(`${origin}${prefix}/goods/search?keywords=${keywords}`, { headers: default_header }).then(res => res.json());
}

export function AcceptGoods(out_trade_no: string) {
    return fetch(`${origin}${prefix}/order/receive/confirm/${out_trade_no}`, { method: 'POST', headers: default_header }).then(res => res.json());
}