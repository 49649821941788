export function getCurrentClientType() : 'weixin' | 'alipay' | 'other' {
    let env = 'other'
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
      env = 'weixin'
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      env = 'alipay'
    }
    return env as any;
}

export function getCookie(name: string) {
  const match = document.cookie.match(
    new RegExp(`${name}=(.*?)(;|$)`)
  ) 
  if (match) {
    return match[1]
  }
  return null;
}
