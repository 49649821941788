import { createContext } from 'react';
export const AppContext = createContext(null);
export enum Page {
    Home,
    Cart,
    Profile,
}
export const AppReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'page':
            state.page = action.value;
            return { ...state };
        case 'add:cart':
            state.cart.push(action.value);
            return { ...state };
        case 'toggle-cart-checked':
            // 切换选中状态
            // state.cart.forEach((item: any) => {
            // });
            // console.log(state);
            
            return { ...state };
        default:
            return state;
    }
}
