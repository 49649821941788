import React from 'react';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import './App.css';
import '@fontsource/roboto/400.css';
import BottomNav from './components/BottomNav';
// import AppBar from './components/AppBar';
import './assets/css/iconfont.css';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { AppContext, Page, AppReducer} from './reducer';
// import Cart from './pages/Cart';
import Confirm from './pages/Cart/confirm';
import PaymentSuccess from './pages/Cart/payment';
// import Profile from './pages/Profile';
// import Detail from './pages/Detail';
// import Home from './pages/Home';
import { useMachine } from '@xstate/react';
import { appMachine } from './state';
import { ConfigProvider } from 'react-vant';
import { getCurrentClientType, getCookie } from './utils/index';
import {FetchCart } from './api/index';
import loadable from '@loadable/component';

const LoadCart = loadable(() => import('./pages/Cart'));
const LoadHome = loadable(() => import('./pages/Home'));
const LoadConfirm = loadable(() => import('./pages/Cart/confirm'));
const LoadProfile = loadable(() => import('./pages/Profile'));
const LoadDetail = loadable(() => import('./pages/Detail'));
const LoadBottomNav = loadable(() => import('./components/BottomNav'));
const LoadAppBar = loadable(() => import('./components/AppBar'));
const LoadChat = loadable(() => import('./pages/Chat'));
const LoadGroup = loadable(() => import('./pages/Group'));

const theme = createTheme({
  palette: {
    primary: { main: '#12C277'},
    secondary: { main: '#000000' },
    info: { main: '#FFF' }
  }
});

function App() {
  const [state, send] = useMachine(appMachine);
  const latestState = React.useRef(state);
  const [active, setActive] = React.useState(false);
  const [ws, setWS]: [any, any]= React.useState(undefined);
  const [count, setCount] = React.useState(0);
  const sessionid_reg = document.cookie.match(/sessionid=([A-Za-z0-9]{32})/);

	const createWebSocket = () => {
    
		if (active) return true;
		const ws = new WebSocket('ws://life.hnwg.net/api/v1/life/chat/ws/service')
		ws.onmessage = function(event) {
			setActive(true);
			setWS(ws);
      if (!event.data.includes("business_type")) return false;
      const result = JSON.parse(event.data);
      
      if (result.business_type === 'message') {
        // @ts-ignore
        send({ type: 'message', value: result.data});
        if (latestState.current.matches('msg.chat') && latestState.current.context.chat.unread > 0) {
          ws?.send(JSON.stringify({ business_type: 'select', sessionid: sessionid_reg && sessionid_reg[1] }))
        }
      }
		}

		ws.onopen = () => {
			// sessionid=0a178631df0e4973aab22f7cd1240821
			setActive(true);
			setWS(ws);
			ws.send(JSON.stringify({business_type: 'message', sessionid: sessionid_reg && sessionid_reg[1] }));
		}

		ws.onclose = () => {
			setWS(undefined);
			setActive(false);
		}

		ws.onerror = () => {
			setWS(undefined);
			setActive(false);
		}

  }

  React.useEffect(
    () => {
      let url;
      const env = getCurrentClientType();
      if (env === 'weixin') url = window.location.origin + '/jweixin-1.6.0.js';
      // if (env === 'alipay') url = window.location.origin + '/alipayjsapi-3.1.1.min.js';
      const script = document.createElement('script');
      if (url) {
        script.src = url;
        // script.async = true;
        script.onload = () => {
          switch (env) {
            case 'weixin':
              // getWxConfig('https://life.hnwg.net/')
              //   .then(res => {
                  // if (res.result !== 'success') return '微信配置信息获取失败';
                  // setTimeout(() => {
                    // wx.config({
                    //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    //   appId: 'wx272f5a364874daea', // 必填，公众号的唯一标识
                    //   timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    //   nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                    //   signature: res.data.signature,// 必填，签名
                    //   jsApiList: ['getLocation', 'openLocation', 'updateAppMessageShareData'], // 必填，需要使用的JS接口列表
                    // })
                    // wx.ready(function() {
                    //   wx.getLocation({
                    //     type: 'gcj02',
                    //     success: function(res: any) {
                    //         var lat = res.latitude;
                    //         var lng = res.longitude;
                    //     }
                    //   });
                    // });
                  // }, 500)
                // });
              // 微信逻辑
              break;
            case 'alipay':
              break;
            default:
              break;
          }
        };
        script.onerror = () => {};
        document.body.appendChild(script);
      }

      // 获取用户购物车
      FetchCart().then((res) => {
        if (res.result === 'success') {
          // @ts-ignore
          send({ type: 'init_cart', value: res.data.cart_list })
        }
      })

      // 开启websocket链接
      createWebSocket();
    },
    []
  )

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count + 1)
      if (!active) createWebSocket();
    }, 8 * 1000)
    return () => clearTimeout(timer)
  }, [count])

  React.useEffect(() => {
    latestState.current = state;
  }, [state])
  return (
    <React.Fragment>
      <ScopedCssBaseline enableColorScheme sx={{ height: '100%'}}>
        <AppContext.Provider value={{state, send, ws} as any}>
          <ThemeProvider theme={theme}>
            {/* vant的主题配置 */}
            <ConfigProvider style={{height: '100%'}}>
            {
              <div className="App">
                {
                  state.matches('home') &&  <LoadAppBar></LoadAppBar>
                }
                {
                  state.matches('home') &&  <LoadHome></LoadHome>
                }
                {
                  state.matches('cart.list') &&  <LoadCart></LoadCart>
                }
                {
                  state.matches('cart.confirm') &&  <LoadConfirm></LoadConfirm>
                }
                {
                  state.matches('group') &&  <LoadGroup></LoadGroup>
                }
                {
                  state.matches('msg') &&  <LoadChat></LoadChat>
                }
                {
                  state.matches('profile') &&  <LoadProfile></LoadProfile>
                }
                {
                  state.matches('payment') &&  <PaymentSuccess></PaymentSuccess>
                }
                {
                  state.matches('detail') &&  <LoadDetail></LoadDetail>
                }
                {/* 不显示底部导航 */}
                {
                  !state.matches('detail') &&
                  !state.matches('msg.chat') &&
                  !state.matches('cart.confirm') &&
                  !state.matches('profile.order') &&
                  !state.matches('profile.detail') &&
                  !state.matches('profile.address') &&
                  !state.matches('profile.select_addr') &&
                  !state.matches('group') &&
                   <LoadBottomNav></LoadBottomNav>
                }
              </div>
            }
            </ConfigProvider>
          </ThemeProvider>
        </AppContext.Provider>
      </ScopedCssBaseline>
    </React.Fragment>
  );
}

export default App;
